import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Auth() {
  const [loading, setLoading] = useState(true);
  const svgString1 = encodeURIComponent(
    `<svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><path fill="#1c1c1c" d="M25.6,-41.7C37.8,-37.2,55.7,-39.8,68.3,-34.1C80.9,-28.3,88.3,-14.2,84.5,-2.2C80.7,9.8,65.9,19.7,54.4,27.4C42.9,35.1,34.8,40.6,26.3,44.8C17.8,49,8.9,51.9,0.9,50.4C-7.2,48.9,-14.4,43.1,-28.3,42.1C-42.2,41,-62.9,44.6,-75.2,38.4C-87.5,32.2,-91.3,16.1,-91.6,-0.2C-91.9,-16.5,-88.8,-32.9,-79.6,-44.5C-70.4,-56.1,-55.2,-62.8,-41,-66.1C-26.7,-69.4,-13.4,-69.4,-3.4,-63.6C6.7,-57.7,13.3,-46.2,25.6,-41.7Z" transform="translate(50 70)" /></svg>`
  );
  const svgString2 = encodeURIComponent(
    `<svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg"><path fill="#1c1c1c" d="M25.6,-41.7C37.8,-37.2,55.7,-39.8,68.3,-34.1C80.9,-28.3,88.3,-14.2,84.5,-2.2C80.7,9.8,65.9,19.7,54.4,27.4C42.9,35.1,34.8,40.6,26.3,44.8C17.8,49,8.9,51.9,0.9,50.4C-7.2,48.9,-14.4,43.1,-28.3,42.1C-42.2,41,-62.9,44.6,-75.2,38.4C-87.5,32.2,-91.3,16.1,-91.6,-0.2C-91.9,-16.5,-88.8,-32.9,-79.6,-44.5C-70.4,-56.1,-55.2,-62.8,-41,-66.1C-26.7,-69.4,-13.4,-69.4,-3.4,-63.6C6.7,-57.7,13.3,-46.2,25.6,-41.7Z" transform="translate(15 70)" /></svg>`
  );
  const svgString3 = encodeURIComponent(
    `<svg viewBox="0 0 90 60" xmlns="http://www.w3.org/2000/svg"><path fill="#1c1c1c" d="M25.6,-41.7C37.8,-37.2,55.7,-39.8,68.3,-34.1C80.9,-28.3,88.3,-14.2,84.5,-2.2C80.7,9.8,65.9,19.7,54.4,27.4C42.9,35.1,34.8,40.6,26.3,44.8C17.8,49,8.9,51.9,0.9,50.4C-7.2,48.9,-14.4,43.1,-28.3,42.1C-42.2,41,-62.9,44.6,-75.2,38.4C-87.5,32.2,-91.3,16.1,-91.6,-0.2C-91.9,-16.5,-88.8,-32.9,-79.6,-44.5C-70.4,-56.1,-55.2,-62.8,-41,-66.1C-26.7,-69.4,-13.4,-69.4,-3.4,-63.6C6.7,-57.7,13.3,-46.2,25.6,-41.7Z" transform="translate(15 70)" /></svg>`
  );
  const svgDataUrl1 = `data:image/svg+xml;utf8,${svgString1}`;
  const svgDataUrl3 = `data:image/svg+xml;utf8,${svgString1}`;
  const svgDataUrl2 = `data:image/svg+xml;utf8,${svgString2}`;
  useEffect(() => {
    const uid = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];
    if (uid && token) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_ACTIVATION_URL}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          uid,
          token,
        },
      };
      console.log(
        `${process.env.REACT_APP_ACTIVATION_URL}`,
        '${process.env.REACT_APP_ACTIVATION_URL}',
        config
      );
      axios
        .request(config)
        .then((response) => {
          console.log(`got approved-------->`, response);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, `did not get approved------------->`);
          
        });
    }
  }, []);
  return (
    <div className='homeWrapper'>
      <div className='homeWrapper-description-background'>
        <span />
      </div>
      <div
        class='homeWrapper-rightSideArt'
        id='homeWrapper-rightSideArt-approved'
      >
        <div />
      </div>
      <div class='homeWrapper-name'>
        <span>MyReflection. ai</span>
      </div>
      <div class='homeWrapper-slogen' />
      <div
        class='homeWrapper-description'
        id='homeWrapper-description-approved'
      >
        {!loading ? (
          <span>
            Your account has been successfully activated. <br />
            Thank You for Signing Up! <br />
            You're all set to start using our services. We're excited to have
            you on board!
          </span>
        ) : (
          <span className='beat-loader'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
          </span>
        )}
      </div>
    </div>
  );
}

export default Auth;
