import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Home() {

  return (
    <div className='homeWrapper'>
      <div className='homeWrapper-description-background'>
        <span />
      </div>
      <div className='homeWrapper-rightSideArt'></div>
      <Link to='/' className='homeWrapper-name' >
        <span>MyReflection. ai</span>
      </Link>
      <div className='homeWrapper-slogen'>
        <div />
        <span>
          Create your interactive digital twin and transform your memories
        </span>
      </div>
      <div className='homeWrapper-description'>
        <span>
          Myreflection.ai aims to immortalize personal legacies by creating
          digital clones that learn from a user's life patterns, enabling
          continuous and meaningful interactions with loved ones beyond one's
          lifetime.
        </span>
          <Link to='/terms&conditions' className='terms'>Terms and Conditions</Link>
          <Link to='/PrivacyPolicy' className='privacy'>Privacy Policy</Link>

      </div>
      <div className='homeWrapper-input-wrapper-btn'>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLSfQWWC5QRixVadGZ8eqgsDI7eyFXgaO_oaaEoNMBK6CwPlQug/viewform?usp=sf_link'>
          Register
        </a>
      </div>
    </div>
  );
}

export default Home;
