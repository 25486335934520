import React from 'react';
import { Link } from 'react-router-dom';

const TermsNConditions = () => {
  return (
    <div className='termsWrapper'>
      <Link to='/' className='termsWrapper-name'>
        <span>MyReflection. ai</span>
      </Link>
      <div className='terms-container'>
        <div className='terms-content-container'>
          <h1 className='terms-title'>Terms of Service</h1>
          <p className='terms-text'>
            Welcome to MyReflection! We're happy you've chosen to use our
            services. These Terms of Service ("Terms") govern your use of the
            MyReflection website, mobile applications, and all related services
            (collectively, the "Services") provided by MyReflection LLC ("us",
            "we", or "our"). Please read these Terms carefully before using our
            Services. By accessing or using the Services, you agree to be bound
            by these Terms and our Privacy Policy. If you do not agree with
            these Terms, do not use the Services.
          </p>
          <h2 className='terms-index'>1. Our Services</h2>
          <p className='terms-text'>
            MyReflection provides an AI companion chatbot service that engages
            in conversations with users to build memories over time. These
            memories, along with AI-generated insights, are used to personalize
            future interactions to ultimately create a digital clone of the
            user. Currently, the service is free, but in the future, we will
            charge a subscription fee that may be tiered. Additional premium
            features may be added later to expand the capabilities of the
            chatbot to serve as a personal assistant. Our ultimate promise is to
            enable the transfer of the digital clone to persons of the user's
            choice at their desired time.
          </p>
          <h2 className='terms-index'>2. Your Account</h2>
          <p className='terms-text'>
            You may need to register for an account to access some or all of our
            Services. You must not be a minor to use our Services. If you are a
            minor, you are not permitted to use our Services for any reason. You
            agree to provide accurate, current and complete information during
            registration and to keep your account information updated. You are
            solely responsible for the activity that occurs on your account, and
            you must keep your account password secure. You agree to notify us
            immediately of any unauthorized access or use of your account. We
            are not liable for any loss or damage from your failure to comply
            with this security obligation.
          </p>
          <h2 className='terms-index'>3. Payment and Subscriptions</h2>
          <p className='terms-text'>
            Our Services are currently free, but in the future, we will charge a
            subscription fee to access some or all features. Subscription
            options and pricing will be described on our website or mobile app.
            Payment will be charged on a recurring basis at the start of each
            subscription period. Subscription fees are non-refundable except as
            required by law. We reserve the right to adjust pricing at any time.
            We currently do not offer refunds except in our sole discretion or
            where legally required.
          </p>
          <h2 className='terms-index'>4. Your Use of the Services</h2>
          <p className='terms-text'>
            Your right to access and use our Services is personal to you and is
            not transferable to any other person or entity. You may only access
            our Services using authorized means. It is your responsibility to
            obtain and pay for all equipment and services needed to access our
            Services. You agree to use the Services only for purposes that are
            legal, proper and in accordance with these Terms and applicable
            laws, regulations or generally accepted practices or guidelines.
          </p>
          <p className='terms-text'>
            You may not:
            <br />- Use the Services in any manner that could damage, disable,
            overburden, or impair our servers or networks
            <br />- Attempt to gain unauthorized access to the Services,
            accounts, servers, or networks
            <br />- Use any robot, spider, scraper or other automated means to
            access the Services for any purpose
            <br />- Harass, threaten or intimidate any of our employees or
            agents
            <br />- Attempt to circumvent any content-filtering techniques we
            employ
            <br />- Violate any applicable law or regulation, or encourage or
            enable any other individual to do so
          </p>
          <p className='terms-text'>
            We reserve the right to terminate or restrict your use of our
            Services for any reason, with or without notice, including if we
            reasonably believe you have violated these Terms or any applicable
            law or regulation. We also reserve the right to terminate or suspend
            the Services in their entirety at any time for any reason without
            notice.
          </p>
          <h2 className='terms-index'>5. Intellectual Property</h2>
          <p className='terms-text'>
            Our Services and their entire contents, features and functionality
            (including but not limited to all information, software, text,
            displays, images, video and audio, and the design, selection and
            arrangement thereof) are owned by MyReflection, its licensors or
            other content suppliers and are protected by United States and
            international intellectual property laws. You shall not reproduce,
            distribute, modify, create derivative works of, publicly display,
            publicly perform, republish, download, store or transmit any of the
            material on our Services except for your personal, non-commercial
            use.
          </p>
          <h2 className='terms-index'>6. User Content</h2>
          <p className='terms-text'>
            Our Services may allow you to submit, upload, publish or otherwise
            make available content such as memories, text, images and other
            materials ("User Content"). You retain ownership of any intellectual
            property rights that you hold in your User Content. When you upload
            or otherwise submit User Content to our Services, you give us the
            right and license to store, reproduce, modify, create derivative
            works of, and distribute your User Content solely for the purpose of
            operating, developing, providing, and improving the Services. You
            are solely responsible for the User Content you post to the Services
            and the consequences of sharing it with others.
          </p>
          <p className='terms-text'>
            By submitting User Content, you affirm, represent and warrant that:
            <br />- You are the creator and owner or have the necessary
            licenses, rights, consents and permissions to authorize us to use
            and distribute your User Content as necessary to operate the
            Services
            <br />- Your User Content does not and will not violate third-party
            rights of any kind, including intellectual property rights or the
            privacy, publicity, or other personal rights of others
            <br />- Your User Content could not be deemed offensive, illegal,
            inappropriate or objectionable
          </p>
          <p className='terms-text'>
            We reserve the right (but have no obligation) to remove or modify
            User Content for any reason, including User Content that we believe
            violates these Terms.
          </p>
          <h2 className='terms-index'>
            7. Disclaimers and Limitation of Liability
          </h2>
          <p className='terms-text'>
            YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. EXCEPT AS OTHERWISE
            PROVIDED HEREIN, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT:
            <br />
            (A) THE SERVICES WILL MEET YOUR REQUIREMENTS;
            <br />
            (B) THE SERVICES WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
            SECURE OR ERROR-FREE BASIS; OR
            <br />
            (C) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER
            MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET
            YOUR EXPECTATIONS.
          </p>
          <p className='terms-text'>
            WE ARE NOT RESPONSIBLE FOR ANY INTERRUPTIONS, ERRORS, OR BUGS IN THE
            SERVICES. WE MAY FROM TIME TO TIME, IN OUR SOLE DISCRETION, ADD OR
            REMOVE FEATURES, IMPOSE LIMITS ON CERTAIN FEATURES OR RESTRICT
            ACCESS TO PARTS OR ALL OF THE SERVICES WITHOUT NOTICE OR LIABILITY.
          </p>
          <p className='terms-text'>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT MYREFLECTION WILL NOT BE
            LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
            LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, RESULTING FROM:
            <br />
            (I) THE USE OR INABILITY TO USE THE SERVICES;
            <br />
            (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR USER CONTENT;
            <br />
            (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; OR
            <br />
            (IV) ANY OTHER MATTER RELATING TO THE SERVICES.
          </p>
          <p className='terms-text'>
            OUR TOTAL LIABILITY TO YOU FOR ANY CLAIMS UNDER THESE TERMS SHALL
            NOT EXCEED THE AMOUNT PAID BY YOU TO MYREFLECTION DURING THE THREE
            (3) MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
            THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF
            DAMAGES. ACCORDINGLY, SOME OF THESE LIMITATIONS MAY NOT APPLY TO
            YOU.
          </p>
          <h2 className='terms-index'>8. Indemnification</h2>
          <p className='terms-text'>
            You agree to defend, indemnify and hold harmless MyReflection and
            its officers, directors, employees and agents from and against any
            claims, disputes, demands, liabilities, damages, losses, costs and
            expenses, including reasonable attorneys' fees, arising out of or in
            any way connected with (i) your access to or use of the Services;
            (ii) your User Content; (iii) your violation of any law or the
            rights of any third party; or (iv) your violation of these Terms.
          </p>
          <h2 className='terms-index'>
            9. Data Security and Breach Notification
          </h2>
          <p className='terms-text'>
            We take reasonable measures to protect the security of your User
            Content and personal information. However, no data transmission over
            the Internet or data storage system can be guaranteed to be 100%
            secure. If we become aware of a security breach that compromises
            your personal information, we will notify you as soon as possible in
            accordance with applicable law. In the event of a breach, our
            liability shall be limited to the fullest extent permitted by
            applicable law.
          </p>
          <h2 className='terms-index'>10. Third-Party Services</h2>
          <p className='terms-text'>
            The App may contain links to or integrate with third-party websites,
            services, and applications, including AI services used to provide
            responses to users. MyReflection is not responsible for the privacy
            practices or content of these third parties. Your use of third-party
            services is at your own risk and subject to the terms and conditions
            and privacy policies of those third parties. To the fullest extent
            permitted by law, MyReflection shall not be liable for any damages
            or losses arising from your use of any third-party services or for
            the acts or omissions of any third-party providers.
          </p>
          <h2 className='terms-index'>11. Dispute Resolution</h2>
          <p className='terms-text'>
            These Terms and any disputes arising out of or related to these
            Terms will be governed by the laws of the State of Nevada, without
            regard to its conflicts of law provisions. You agree that any
            dispute between you and MyReflection will be resolved through
            binding arbitration on an individual basis in accordance with the
            American Arbitration Association's rules for arbitration of
            consumer-related disputes and you and MyReflection hereby expressly
            waive trial by jury. The arbitration will be conducted in [county,
            state] by a single arbitrator. Payment of all filing, administration
            and arbitrator fees will be governed by the AAA's rules. The
            arbitral award will be final and binding. Judgment on the award may
            be entered in any court having jurisdiction thereof.
          </p>
          <p className='terms-text'>
            Any legal action or proceeding arising under these Terms that is not
            subject to arbitration will be brought exclusively in the federal or
            state courts located in Clark County, Nevada, and you irrevocably
            consent to the personal jurisdiction and venue there.
          </p>
          <h2 className='terms-index'>12. Modifications to Terms</h2>
          <p className='terms-text'>
            We reserve the right to modify these Terms at any time, in our sole
            discretion, and will publish notice of any such modifications on our
            website or notify you by email to the address associated with your
            account. By continuing to access or use our Services after we have
            provided you with notice of a modification, you are agreeing to be
            bound by the modified Terms. You are expected to check this page
            frequently so you are aware of any changes, as they are binding on
            you.
          </p>
          <h2 className='terms-index'>13. Termination</h2>
          <p className='terms-text'>
            We may terminate or suspend your access to all or part of the
            Services at any time, with or without cause, with or without notice,
            effective immediately, which may result in the deletion of your User
            Content. If you wish to terminate your account, you may do so by
            following the instructions on the Services. All provisions of these
            Terms which by their nature should survive termination will survive
            termination, including ownership provisions, warranty disclaimers,
            limitations of liability, and dispute resolution provisions.
          </p>
          <h2 className='terms-index'>14. Entire Agreement</h2>
          <p className='terms-text'>
            These Terms constitute the entire agreement between you and
            MyReflection with regard to your use of the Services, and supersede
            all previous written or oral agreements. If any part of these Terms
            is determined to be invalid or unenforceable pursuant to applicable
            law, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision, and the remainder of
            the Terms shall continue in effect.
          </p>
          <h2 className='terms-index'>15. Force Majeure</h2>
          <p className='terms-text'>
            We shall not be liable for any delay or failure to perform resulting
            from causes outside our reasonable control, including, but not
            limited to, acts of God, war, terrorism, riots, embargos, acts of
            civil or military authorities, fire, floods, accidents, strikes or
            shortages of transportation facilities, fuel, energy, labor or
            materials.
          </p>
          <h2 className='terms-contact'>Contact Us</h2>
          <p className='terms-text'>
            If you have any questions about these Terms, please contact us at
            <Link
              style={{ color: '#0eea96', textDecoration: 'none' }}
            >{`   support@myreflection.ai`}</Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsNConditions;
